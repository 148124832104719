import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import cryptoRandomString from "crypto-random-string";
import Cookies from "js-cookie";
import S3FileUpload from "react-s3";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Capacitor } from "@capacitor/core";
import { Container, BreadcrumbItem,Alert } from "reactstrap";
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const Report = (props) => {
  const [likes, setLikes] = useState(0);
  const [shares, setShares] = useState(0);
  const [comments, setComments] = useState(0);
  const [file, updateFile] = useState(null);
  const [fileName, updateFileName] = useState("Upload Image");
  const history = useHistory();
  const s3Config = {
    bucketName: "genni-creator-assets",
    region: "us-east-1",
    accessKeyId: process.env.REACT_APP_ACCESS_KEY2,
    secretAccessKey: `${process.env.REACT_APP_SECRET_ACCESS_KEY2}`,
  };
  
  const [fileResult, updateFileResult] = useState(null);
  const [swipes, setSwipes] = useState(0);
  
  const [stickerTaps, setStickerTaps] = useState(0);
  const [views, setViews] = useState(0);
  const [error, setError] = useState(null);
  const location = useLocation();
  const campaignType = location.pathname.slice(11);
  let params = queryString.parse(location.search);
  const loginToken = LoginToken(); // => 'value'
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleLikesChange = (e) => {
    setLikes(e.target.value);
  };

  const handleViewsChange = (e) => {
    setViews(e.target.value);
  };

  const handleSharesChange = (e) => {
    setShares(e.target.value);
  };

  const handleSwipesChange = (e) => {
    setSwipes(e.target.value);
  };

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  const handleStickerTapsChange = (e) => {
    setStickerTaps(e.target.value);
  };

  const [data, setData] = useState();

  useEffect(() => {
    fetch(
      `https://api.genni.com/campaignResults?x-api-key=${loginToken}&postid=${params.PostID}&uid=${params.UID}`,
      { method: "GET" }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          setData(data);
        } else {
          // window.location.href = "/login";
        }
      });
  }, []);

  function handleImageChange(event) {
    const {
      target: { value, files },
    } = event;
    const fileForUpload = files[0];
    if (fileForUpload.type.includes("pdf") || fileForUpload.type.includes("jpeg") || fileForUpload.type.includes("png")){
      setError("");
      let reader = new FileReader();
      let url = reader.readAsDataURL(fileForUpload);

      reader.onloadend = function (e) {
        updateFileResult([reader.result]);
      }.bind(this);
      if (fileForUpload) {
        updateFileName(fileForUpload.name.split(".")[0]);
        updateFile(fileForUpload || value);
      }
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      setError("Invalid File Type");
    }
  }

  
  // console.log(data);
  const reportStats = () => {
    const new_file = new File([file], makeid(32));

    
    try {
      S3FileUpload.uploadFile(new_file, s3Config)
        .then(async (data) => {
          fetch(
            `https://api.genni.com/campaignResults?x-api-key=${loginToken}&PostID=${params.PostID}&UID=${params.UID}&Swipes=${swipes}&StickerTaps=${stickerTaps}&Likes=${likes}&Shares=${shares}&Comments=${comments}`,
            {
              method: "POST",
              headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
              }),
              body: `Views=${views}&fileUrl=${data.location}`, // <-- Post parameters
            }
          )
            .then(async (res) => {
              if (res.ok) {
                return res.json();
              } else {
                await res.text().then((text) => {
                  const errText = new Error(text);
                  setError(JSON.parse(errText.message).messages[0]);
                });
              }
              throw res;
              //const jsonRes = JSON.parse(res);
            })
            .then((data) => {
              if (!data.error) {
                history.push(`/detail?PostID=${params.PostID}&UID=${params.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`);
              } else {
                // console.log(data);
                setError("An Error Has Occured");
                // setError(data.data.FormSubmissionResponseMessage);
              }
            });
        });
    } catch (err) {
      // console.log("error: ", err);
      setError("An Error Has Occured");
    }
    
  };
  // console.log(data);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          {Breadcrumbs(params.PostID, "Campaigns", `/campaigns${TokenAppenditure()}`)}

            
          <div className="mt-4">
            <a
              href={`/detail/?PostID=${params.PostID}&UID=${params.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`}
              style={{ display: "flex" }}
            >
              <i
                className="mdi mdi-chevron-left text-pink"
                style={{ fontSize: 24, marginTop: -7 }}
              ></i>{" "}
              <d className="text-pink">Back</d>
            </a>
            {error && <Alert color="danger">{error}</Alert>}
            <Container className="card p-3">
              <h5 className="madeGentle text-black mb-3">
                {data && data.data.Row_PostResultsForm.Header}
              </h5>
              <AvForm>
                {/* <div className="campaign">
                  <div className="campaign-details">
                    {data &&
                      data.Data.Row_CampaignDetails.Fields.map((a, count) => {
                        return (
                          <>
                            <div className="campaign-detail">
                              <p className="font-weight-bold madeGentle text-black">
                                {a.FieldName}
                              </p>
                              <p className="cmpgn-value">{a.FieldValue}</p>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div> */}
                <div className="campaigns">
                  {data &&
                    data.data.Row_PostResultsForm.Fields.filter(
                      (e) => e.InputLabel === "Views"
                    ).length > 0 && (
                      <div className="campaign">
                        <div className="campaign-title">
                          <p className="text-pink madeGentle">Views</p>
                        </div>
                        <AvField
                          name="views"
                          type="number"
                          step="1"
                          className="inputField"
                          errorMessage="Invalid Views"
                          value={views}
                          onChange={handleViewsChange}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </div>
                    )}
                  {data &&
                    data.data.Row_PostResultsForm.Fields.filter(
                      (e) => e.InputLabel === "Shares"
                    ).length > 0 && (
                      <div className="campaign">
                        <div className="campaign-title">
                          <p className="text-pink madeGentle">Shares</p>
                        </div>
                        <AvField
                          name="shares"
                          type="number"
                          step="1"
                          className="inputField"
                          errorMessage="Invalid Shares"
                          value={shares}
                          onChange={handleSharesChange}
                        />
                      </div>
                    )}

                  {data &&
                    data.data.Row_PostResultsForm.Fields.filter(
                      (e) => e.InputLabel === "Comments"
                    ).length > 0 && (
                      <div className="campaign">
                        <div className="campaign-title">
                          <p className="text-pink madeGentle">Comments</p>
                        </div>
                        <AvField
                          name="comments"
                          type="number"
                          step="1"
                          className="inputField"
                          errorMessage="Invalid Comments"
                          value={comments}
                          onChange={handleCommentsChange}
                        />
                      </div>
                    )}

                  {data &&
                    data.data.Row_PostResultsForm.Fields.filter(
                      (e) => e.InputLabel === "Swipes"
                    ).length > 0 && (
                      <div className="campaign">
                        <div className="campaign-title">
                          <p className="text-pink madeGentle">Swipes</p>
                        </div>
                        <AvField
                          name="swipes"
                          type="number"
                          step="1"
                          className="inputField"
                          errorMessage="Invalid Swipes"
                          value={swipes}
                          onChange={handleSwipesChange}
                        />
                      </div>
                    )}

                  {data &&
                    data.data.Row_PostResultsForm.Fields.filter(
                      (e) => e.InputLabel === "StickerTaps"
                    ).length > 0 && (
                      <div className="campaign">
                        <div className="campaign-title">
                          <p className="text-pink madeGentle">StickerTaps</p>
                        </div>
                        <AvField
                          name="stickerTaps"
                          type="number"
                          className="inputField"
                          errorMessage="Invalid Sticker Taps"
                          value={stickerTaps}
                          onChange={handleStickerTapsChange}
                        />
                      </div>
                    )}

                  {data &&
                    data.data.Row_PostResultsForm.Fields.filter(
                      (e) => e.InputLabel === "Screenshot Image"
                    ).length > 0 && (
                      <div className="campaign">
                        <div className="campaign-title">
                          <p className="text-pink madeGentle">
                            Screenshot of Views
                          </p>
                        </div>
                        {file ? (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <img
                              src={fileResult}
                              alt="file"
                              style={{ width: 40, minWidth:40 }}
                            />
                            
                          </div>
                        ) : (
                          <>
                            <label
                              htmlFor="file-upload"
                              className="btn btn-secondary float-right"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth:120,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "16px",
                              }}
                            >
                              Upload File
                            </label>
                            <input
                              type="file"
                              id="file-upload"
                              onChange={handleImageChange}
                              style={{ width: "140px", display: "none" }}
                            />
                          </>
                        )}
                      </div>
                    )}

                  <div className="campaign">
                    
                    <div
                      className={`btn btn-pink gradient-pink full-width float-right mt-2 ${!file && 'disabled'}`}
                      style={{ width: "100%" }}
                      onClick={reportStats}
                    >
                      Submit
                    </div>
                    <span className="text-danger">{!file && 'Please complete the form'}</span>
                  </div>
                </div>
              </AvForm>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};;


export default connect()(withRouter(Report));
