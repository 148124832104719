import React from "react"
import { Redirect } from "react-router-dom"
import { Capacitor } from "@capacitor/core"
import Dashboard from "../pages/Dashboard/index"
import Campaigns from "../pages/Campaigns/index";
import Report from "../pages/CampaignDetail/report";
import Boost from "../pages/CampaignDetail/boost";
import Payments from "../pages/Payments/index";
import Analytics from "../pages/Analytics/index";
import Admin from "../pages/Admin/index";
import Contests from "../pages/Contests/index";
import Explore from "../pages/Explore/index";
import Notifications from "../pages/Notifications/index";
import Contact from "../pages/Contact/index";
import PublicContact from "../pages/Contact/PublicContact";
import TrackersContact from "../pages/Contact/TrackersContact";
import Feedback from "../pages/Feedback/index";
import Base from "../pages/Base/index";
import LandingPage from "../pages/LandingPage/index";
import ContestDetail from "../pages/ContestDetail/index";
import ExclusiveDetail from "../pages/ExclusiveDetail/index";
import FAQ from "../pages/FAQ/index";
import FAQPage from "../pages/FAQPage/index";
import FAQPageList from "../pages/FAQPage/faqlist";
import FAQList from "../pages/FAQ/faqlist";
import Detail from "../pages/CampaignDetail/index";
import AccountSettings from "../pages/AccountSettings/index";
import ContactInformation from "../pages/AccountSettings/contact";
import PaymentInformation from "../pages/AccountSettings/payment";
import TaxInformation from "../pages/AccountSettings/tax";
import Account from "../pages/AccountSettings/accounts";
import SocialInformation from "../pages/AccountSettings/social";
import UpdateSocial from "../pages/AccountSettings/updatesocial";
import ChangePassword from "../pages/AccountSettings/password";
import ChangePhoto from "../pages/AccountSettings/photo";
import DeactivateAccount from "../pages/AccountSettings/deactivate";
import Terms from "../pages/Utility/terms"
import Privacy from "../pages/Utility/privacy"
// Authentication related pages
import NativeLogin from "../pages/Authentication/NativeLogin"
import Welcome from "../pages/Authentication/Welcome"
import Login from "../pages/Authentication/Login"
import ManagerOnboarding from "../pages/Authentication/ManagerOnboarding"
import UpdatePayment from "../pages/Authentication/UpdatePayment"
import Route from "../pages/Authentication/Route/Route";
import Confirm from "../pages/Authentication/Confirm";
import Hello from "../pages/Authentication/Hello";
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPwd from "../pages/Authentication/ResetPassword"
import MarketingPortalContact from "../pages/Contact/MarketingPortalContact";


const userRoutes = [


  // this route should be at the end of all other routes
]

const authRoutes = [
  { path: "/login", component: Capacitor.isNativePlatform() ? Welcome : Login },
  { path: "/confirm", component: Confirm },
  { path: "/route", component: Route },
  { path: "/welcome", component: Welcome },
  { path: "/hello", component: Hello },
  { path: "/manageronboarding", component: ManagerOnboarding },
  { path: "/index", component: Capacitor.isNativePlatform() ? Welcome : Login },
  { path: "/dashboard", component: Dashboard },
  { path: "/contact", component: Contact },
  { path: "/contactus", component: PublicContact },
  { path: "/trackerscontact", component: TrackersContact },
  { path: "/marketingcontact", component: MarketingPortalContact },
  { path: "/feedback", component: Feedback },
  { path: "/analytics", component: Analytics },
  { path: "/contests", component: Contests },
  { path: "/explore", component: Explore },
  { path: "/contestdetail", component: ContestDetail },
  { path: "/directdetail", component: ContestDetail },
  { path: "/exclusivedetail", component: ExclusiveDetail },
  { path: "/faq", component: FAQ },
  { path: "/faqlist", component: FAQList },
  { path: "/settings", component: AccountSettings },
  { path: "/account/admin", component: Admin },
  { path: "/account/contact", component: ContactInformation },
  { path: "/account/vendor", component: PaymentInformation },
  { path: "/account/tax", component: TaxInformation },
  { path: "/account/accounts", component: Account },
  { path: "/account/social", component: SocialInformation },
  { path: "/account/updatesocial", component: UpdateSocial },
  { path: "/account/credentials", component: ChangePassword },
  { path: "/account/photo", component: ChangePhoto },
  { path: "/account/deactivate", component: DeactivateAccount },
  { path: "/detail", component: Detail },
  { path: "/campaigns", component: Campaigns },
  { path: "/notifications", component: Notifications },
  { path: "/report", component: Report },
  { path: "/boost", component: Boost },
  { path: "/payments", component: Payments },
  { path: "/pagefaq", component: FAQPage },
  { path: "/pagefaqlist", component: FAQPageList },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPwd },
  { path: "/terms", component: Terms },
  { path: "/privacy", component: Privacy },

  { path: "/", component: Capacitor.isNativePlatform() ? Welcome : Base },
];

export { userRoutes, authRoutes }