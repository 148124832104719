import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Container, CardBody, Card } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MdError } from "react-icons/md";

import Loader from "react-loader-spinner";

const ResetPasswordPage = (props) => {
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState();
  const [isValidToken, setIsValidToken] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const magicToken = urlParams.get("magic_token");
  const magicTokenUid = urlParams.get("magic_token_uid");

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const res = await fetch(
          `https://event_service.genni.com/magic-link/verify?magic_token=${magicToken}&magic_token_uid=${magicTokenUid}`,
          // `http://localhost:3002/magic-link/verify?magic_token=${magicToken}&magic_token_uid=${magicTokenUid}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          }
        );

        const data = await res.json();

        if (res.ok && data.data[0].success) {
          setIsValidToken(true);
        } else {
          setError(data.data[0].message || "Invalid or expired link. Please request a new reset link.");
          setIsValidToken(false);
        }
      } catch (err) {
        setError("An error has occurred.");
        setIsValidToken(false);
      }
    };

    verifyToken();
  }, [magicToken, magicTokenUid]);

  const reset = async () => {
    try {
      setIsUploading(true);
      const res = await fetch(
        `https://event_service.genni.com/magic-link/verify?magic_token=${magicToken}&magic_token_uid=${magicTokenUid}`,
        // `http://localhost:3002/magic-link/verify?magic_token=${magicToken}&magic_token_uid=${magicTokenUid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            newPassword,
            confirmPassword,
          }),
        }
      );

      const data = await res.json();

      if (res.ok && data.success) {
        // navigate to login page with success message
        history.push("/login?reset_success=true");
        setIsUploading(false);
      } else {
        setError(data.message || "An error occurred. Please try again.");
        setIsUploading(false);
      }
    } catch (err) {
      setError("An error has occurred.");
    }
  };

  return (
    <React.Fragment>
      <div className="bg-pink" style={{ height: 10, width: "100%" }}></div>
  
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <Card>
                <CardBody className="p-3">
                  {isValidToken === null || isUploading ? (
                    <p>Loading...</p>
                  ) : isValidToken ? (
                    <div className="text-center mt-2">
                      <h4 className="madeGentle">Reset Password</h4>
                      <p className="text-muted">
                        Your identity has been verified! Please enter your new password and confirm it.
                      </p>
                      <AvForm className="form-horizontal" onValidSubmit={reset}>
                        <div className="mb-3 text-start">
                          <h6>Enter New Password</h6>
                          <AvField
                            name="newPassword"
                            type="password"
                            onChange={handleNewPasswordChange}
                            value={newPassword}
                            className="inputField"
                            placeholder="Enter your new password"
                            style={{ width: '100%' }}
                            validate={{
                              required: { value: true, errorMessage: "Password is required" },
                              minLength: { value: 8, errorMessage: "Password must be at least 8 characters" },
                              pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#]).+$/,
                                errorMessage: "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character",
                              },
                            }}
                          />
                        </div>
                        <div className="mb-3 text-start">
                          <h6>Confirm New Password</h6>
                          <AvField
                            name="confirmPassword"
                            type="password"
                            onChange={handleConfirmPasswordChange}
                            value={confirmPassword}
                            className="inputField"
                            placeholder="Confirm your new password"
                            style={{ width: '100%' }}
                            validate={{
                              required: { value: true, errorMessage: "Confirmation is required" },
                              match: { value: "newPassword", errorMessage: "Passwords do not match" },
                            }}
                          />
                        </div>
                        <p className="text-danger">{error}</p>
                        <Row className="row mb-0">
                          <Col className="col-12 text-end">
                            <button
                              className="btn gradient-pink btn-pink text-white w-100 waves-effect waves-light"
                              onClick={() => {
                                reset();
                              }}
                            >
                              {isUploading ? (
                                <Loader type="Bars" color="#fff" height={20} width={40} />
                              ) : (
                                "Reset"
                              )}
                            </button>
                          </Col>
                        </Row>
                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Remember It?{" "}
                            <Link to="/login" className="fw-medium text-pink">Login</Link>
                          </p>
                        </div>
                      </AvForm>
                    </div>
                  ) : (
                    // Invalid token view
                    <div className="text-center">
                      <MdError size={48} color="red" />
                      <h4 className="mt-3">Invalid or Expired Link</h4>
                      <p className="text-muted">{error}</p>
                      <div className="mt-4 text-center">
                        <Link to="/login" className="fw-medium text-pink">Back to Login</Link>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

ResetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userResetPassword: PropTypes.func,
};

export default withRouter(ResetPasswordPage);
