import React, { useEffect, useState } from "react";
import {
  Progress,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { API } from "aws-amplify";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import { getCompetition, listPrizess } from "../../graphql/queries";
import { get } from "lodash";
import useGeoLocation from "react-ipgeolocation";
import moment from "moment";
import ReactPlayer from "react-player/soundcloud";
import ReactPlayerFile from "react-player/file";
import Skeleton from "react-skeleton-loader";
import Cookies from "js-cookie";
import queryString from "query-string";
import Standings from "./standings.js";
import CreativeStandings from "./creative-standings";
import Navbar from "../../components/HorizontalLayout/Navbar";
import Header from "../../components/HorizontalLayout/Header";
import DirectHeader from "../../components/HorizontalLayout/DirectHeader";

import NativeHeader from "../../components/HorizontalLayout/NativeHeader";
import SubmitPost from "./submit-post";
import { Capacitor } from "@capacitor/core";
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";

function compare(a, b) {
  if (a.likes > b.likes) {
    return -1;
  }
  if (a.likes < b.likes) {
    return 1;
  }
  return 0;
}
const ContestHeader = ({ isDirect }) => {
  const location = useLocation();
  const pathName = get(location, ["pathname"], "");
  const [competition, setCompetition] = useState(null);
  let params = queryString.parse(location.search);
  const loginToken = LoginToken();
  const [prizesKeys, setPrizesKeys] = useState();
  const [isPlaying, setIsPlaying] = useState(0);
  const [hasPromotion, setHasPromotion] = useState(false);
  const [modal, setModal] = useState(false);
  const [myLocation, setMyLocation] = useState(null);
  const geoLocation = useGeoLocation();

  useEffect(() => {
    const fetchLocation = () => {
      if (geoLocation.country !== undefined) {
        // If the country is defined, set it as the location and stop polling
        setMyLocation(geoLocation);
      } else {
        // If the country is still undefined, wait and then fetch again
        setTimeout(fetchLocation, 1000); // Adjust the timeout duration as needed
      }
    };

    if (myLocation === null) {
      // If location is null, start the initial fetch
      fetchLocation();
    }
  }, [geoLocation.country, myLocation]);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [guidelinesModal, setGuidelinesModal] = useState(false);
  const toggleGuidelines = () => {
    setGuidelinesModal(!guidelinesModal);
  };

  const overviewList = () => {
    if (competition && competition.prizes) {
      const firstPrizeKey = competition.prizes[0].split("$")[0];
      const firstPrizeVal = competition.prizes[0].split("$")[1];
      const secondPrizeKey =
        competition.prizes.length > 1
          ? competition.prizes[1].split("$")[0]
          : null;
      const secondPrizeVal =
        competition.prizes.length > 1
          ? competition.prizes[1].split("$")[1]
          : null;
      const thirdPrizeKey =
        competition.prizes.length > 2
          ? competition.prizes[2].split("$")[0]
          : null;
      const thirdPrizeVal =
        competition.prizes.length > 2
          ? competition.prizes[2].split("$")[1]
          : null;
      const fourthPrizeKey =
        competition.prizes.length > 3
          ? competition.prizes[3].split("$")[0]
          : null;
      const fourthPrizeVal =
        competition.prizes.length > 3
          ? competition.prizes[3].split("$")[1]
          : null;
      const fifthPrizeKey =
        competition.prizes.length > 4
          ? competition.prizes[4].split("$")[0]
          : null;
      const fifthPrizeVal =
        competition.prizes.length > 4
          ? competition.prizes[4].split("$")[1]
          : null;
      const sixthPrizeKey =
        competition.prizes.length > 5
          ? competition.prizes[5].split("$")[0]
          : null;
      const sixthPrizeVal =
        competition.prizes.length > 5
          ? competition.prizes[5].split("$")[1]
          : null;

      const seventhPrizeKey =
        competition.prizes.length > 6
          ? competition.prizes[6].split("$")[0]
          : null;
      const seventhPrizeVal =
        competition.prizes.length > 6
          ? competition.prizes[6].split("$")[1]
          : null;

      const eigthPrizeKey =
        competition.prizes.length > 7
          ? competition.prizes[7].split("$")[0]
          : null;
      const eigthPrizeVal =
        competition.prizes.length > 7
          ? competition.prizes[7].split("$")[1]
          : null;
      if (firstPrizeKey && firstPrizeKey.includes("First") && !hasPromotion) {
        setHasPromotion({
          prizeAmount: competition.prizes[0].split("$")[1],
          numOfWinners: competition.prizes[0]
            .split("First ")[1]
            .split(" To")[0],
        });
      } else if (
        secondPrizeKey &&
        secondPrizeKey.includes("First") &&
        !hasPromotion
      ) {
        setHasPromotion({
          prizeAmount: competition.prizes[1].split("$")[1],
          numOfWinners: competition.prizes[1]
            .split("First ")[1]
            .split(" To")[0],
        });
      } else if (
        thirdPrizeKey &&
        thirdPrizeKey.includes("First") &&
        !hasPromotion
      ) {
        setHasPromotion({
          prizeAmount: competition.prizes[2].split("$")[1],
          numOfWinners: competition.prizes[2]
            .split("First ")[1]
            .split(" To")[0],
        });
      } else if (
        fourthPrizeKey &&
        fourthPrizeKey.includes("First") &&
        !hasPromotion
      ) {
        setHasPromotion({
          prizeAmount: competition.prizes[3].split("$")[1],
          numOfWinners: competition.prizes[3]
            .split("First ")[1]
            .split(" To")[0],
        });
      } else if (
        fifthPrizeKey &&
        fifthPrizeKey.includes("First") &&
        !hasPromotion
      ) {
        setHasPromotion({
          prizeAmount: competition.prizes[4].split("$")[1],
          numOfWinners: competition.prizes[4]
            .split("First ")[1]
            .split(" To")[0],
        });
      } else if (
        sixthPrizeKey &&
        sixthPrizeKey.includes("First") &&
        !hasPromotion
      ) {
        setHasPromotion({
          prizeAmount: competition.prizes[5].split("$")[1],
          numOfWinners: competition.prizes[5]
            .split("First ")[1]
            .split(" To")[0],
        });
      } else if (
        seventhPrizeKey &&
        seventhPrizeKey.includes("First") &&
        !hasPromotion
      ) {
        setHasPromotion({
          prizeAmount: competition.prizes[6].split("$")[1],
          numOfWinners: competition.prizes[6]
            .split("First ")[1]
            .split(" To")[0],
        });
      } else if (
        eigthPrizeKey &&
        eigthPrizeKey.includes("First") &&
        !hasPromotion
      ) {
        setHasPromotion({
          prizeAmount: competition.prizes[7].split("$")[1],
          numOfWinners: competition.prizes[7]
            .split("First ")[1]
            .split(" To")[0],
        });
      }
      return (
        <ul
          className="list-unstyled feature-list mb-0-mobile mb-0"
          style={{ alignSelf: "flex-start" }}
        >
          <li className="h6 Archivo" style={{ fontSize: 15 }}>
            <i className="mdi mdi-podium-gold mdi-18px text-pink mr-2 Archivo"></i>
            <span className="text-muted">{firstPrizeKey}</span> {!isDirect && '$'}{firstPrizeVal}
          </li>

          <li className="h6 Archivo" style={{ fontSize: 15 }}>
            {competition.prizes.length > 1 ? (
              <>
                <i className="mdi mdi-podium-silver mdi-18px text-pink mr-2"></i>
                <span className="text-muted">{secondPrizeKey}</span> {!isDirect && '$'}
                {secondPrizeVal}
              </>
            ) : (
              <></>
            )}
          </li>
          {competition.prizes.length > 2 && (
            <li className="h6 Archivo" style={{ fontSize: 15 }}>
              <i className="mdi mdi-medal mdi-18px text-pink mr-2"></i>
              <span className="text-muted">{thirdPrizeKey}</span> {!isDirect && '$'}
              {thirdPrizeVal}
            </li>
          )}
          {competition.prizes.length > 3 && (
            <li className="h6 Archivo" style={{ fontSize: 15 }}>
              <i className="mdi mdi-trophy mdi-18px text-pink mr-2"></i>
              <span className="text-muted">{fourthPrizeKey}</span> {!isDirect && '$'}
              {fourthPrizeVal}
            </li>
          )}
          {competition.prizes.length > 4 && (
            <li className="h6 Archivo" style={{ fontSize: 15 }}>
              <i className="mdi mdi-trophy mdi-18px text-pink mr-2"></i>
              <span className="text-muted">{fifthPrizeKey}</span> {!isDirect && '$'}
              {fifthPrizeVal}
            </li>
          )}

          {competition.prizes.length > 5 && (
            <li className="h6 Archivo" style={{ fontSize: 15 }}>
              <i className="mdi mdi-trophy mdi-18px text-pink mr-2"></i>
              <span className="text-muted">{sixthPrizeKey}</span> {!isDirect && '$'}
              {sixthPrizeVal}
            </li>
          )}
          {competition.prizes.length > 6 && (
            <li className="h6 Archivo" style={{ fontSize: 15 }}>
              <i className="mdi mdi-trophy mdi-18px text-pink mr-2"></i>
              <span className="text-muted">{seventhPrizeKey}</span> {!isDirect && '$'}
              {seventhPrizeVal}
            </li>
          )}
          {competition.prizes.length > 7 && (
            <li className="h6 Archivo" style={{ fontSize: 15 }}>
              <i className="mdi mdi-trophy mdi-18px text-pink mr-2"></i>
              <span className="text-muted">{seventhPrizeKey}</span> {!isDirect && '$'}
              {seventhPrizeVal}
            </li>
          )}

          <li className="h6 Archivo" style={{ fontSize: 15 }}>
            <i className="mdi mdi-calendar mdi-18px text-pink mr-2"></i>
            <span className="text-muted">End Date:</span>{" "}
            {competition &&
              moment.utc(competition.endDate).local().format("MMM Do, h:mm a")}
          </li>
        </ul>
      );
    }

    return (
      <ul className="list-unstyled feature-list mb-0-mobile">
        <li className="h5" style={{ marginBottom: 5 }}>
          <Skeleton width="150px" height="21px" color="#ced5e0" />
        </li>
        <li className="h5" style={{ marginBottom: 5 }}>
          <Skeleton width="150px" height="21px" color="#ced5e0" />
        </li>
        <li className="h5" style={{ marginBottom: 5 }}>
          <Skeleton width="180px" height="21px" color="#ced5e0" />
        </li>
        <li className="h5" style={{ marginBottom: 5 }}>
          <Skeleton width="180px" height="21px" color="#ced5e0" />
        </li>
      </ul>
    );
  };
  function reactPlayer(audio, image) {
    if (
      audio.includes("tiktok.com") ||
      audio.includes("youtube.com") ||
      audio.includes("instagram.com")
    ) {
      return (
        <>
          <button
            className="play-box-big"
            style={{ backgroundImage: "url('" + image + "')" }}
            onClick={() => {
              window.open(audio);
            }}
          >
            <div className="overlay">
              <div className="play-button-big">
                {isPlaying ? (
                  <i
                    className="mdi mdi-pause text-white"
                    style={{ fontSize: 33 }}
                  ></i>
                ) : (
                  <svg
                    viewBox="0 0 26 26"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "100%", height: "100%", color: "white" }}
                  >
                    <title>Play</title>
                    <path
                      d="M7.712 22.04a.732.732 0 0 1-.806.007.767.767 0 0 1-.406-.703V4.656c0-.31.135-.544.406-.703.271-.16.54-.157.806.006l14.458 8.332c.266.163.4.4.4.709 0 .31-.134.546-.4.71L7.712 22.04z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                )}
              </div>
            </div>
          </button>
        </>
      );
    } else {
      return (
        <>
          <ReactPlayerFile
            url={audio}
            playing={isPlaying}
            style={{ display: "none" }}
          />
          <button
            className="play-box-big"
            style={{ backgroundImage: "url('" + image + "')" }}
            onClick={() => {
              setIsPlaying(!isPlaying);
            }}
          >
            <div className="overlay">
              <div className="play-button-big">
                {isPlaying ? (
                  <i
                    className="mdi mdi-pause text-white"
                    style={{ fontSize: 33 }}
                  ></i>
                ) : (
                  <svg
                    viewBox="0 0 26 26"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "100%", height: "100%", color: "white" }}
                  >
                    <title>Play</title>
                    <path
                      d="M7.712 22.04a.732.732 0 0 1-.806.007.767.767 0 0 1-.406-.703V4.656c0-.31.135-.544.406-.703.271-.16.54-.157.806.006l14.458 8.332c.266.163.4.4.4.709 0 .31-.134.546-.4.71L7.712 22.04z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                )}
              </div>
            </div>
          </button>
        </>
      );
    }
  }

  const fetchCompetitions = async () => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: params.PostID },
    });
    setCompetition(apiData.data.getCompetition);
  };

  const fetchPrizess = async () => {
    const apiData = await API.graphql({
      query: listPrizess,
      variables: { limit: 1000 },
    });
    setPrizesKeys(apiData.data.listPrizess.items);
  };

  const returnPrizeDetails = (key) => {
    if (key) {
      // console.log(key);
      if (key.includes("Place") && key.includes("-")) {
        const firstVal = key.split("-")[0];
        const secondVal = key.split("-")[1].split("Place")[0];
        return `The creators whose combined posts receive the ${firstVal} through ${secondVal} most likes`;
      } else if (key.includes("Place") && !key.includes("-")) {
        return `The creator whose combined posts receive the ${
          key.split(" Place:")[0]
        } most likes`;
      } else {
        const keyValue =
          prizesKeys &&
          prizesKeys.filter((e) => {
            return key.toLowerCase().includes(e.Key.toLowerCase());
          });
        return keyValue && keyValue[0] ? keyValue[0].Value : "";
      }
    }
  };

  useEffect(() => {
    fetchCompetitions();
    fetchPrizess();

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const referral = "";
  const competitionId = get(competition, ["id"], null);
  const platforms = get(competition, ["platforms"], ["TikTok"]);
  const competitionType = get(competition, ["competitionType"], "Likes");
  const isActive = get(competition, ["isActive"], false);
  const layloAPIKey = get(competition, ["layloAPIKey"], null);
  const name = get(competition, ["name"], null);
  const organizer = get(competition, ["organizer"], null);
  const posts = get(competition, ["posts", "items"], null);
  const postsArr =
    posts &&
    posts.filter(function (e) {
      return e._deleted !== true;
    });
  const lastUpdated = get(competition, ["lastUpdated"], "");
  const isClosed = get(competition, ["isClosed"], false);

  const creatorObj = {};

  const filteredPosts = posts
    ? posts
        .filter(function (e) {
          if (e._deleted !== true) {
            const creatorId = e.creator.replace(" ", "") + e.platform;
            if (!creatorObj[creatorId]) {
              creatorObj[creatorId] = {
                creator: e.creator,
                platform: e.platform,
                likes: e.likes,
                posts: 1,
                link: e.link,
              };
            } else {
              const tempLikes = creatorObj[creatorId]["likes"];
              const tempPosts = creatorObj[creatorId]["posts"];
              const tempLink =
                tempLikes < e.likes ? e.link : creatorObj[creatorId]["link"];
              creatorObj[creatorId] = {
                creator: e.creator,
                platform: e.platform,
                likes: tempLikes + e.likes,
                posts: tempPosts + 1,
                link: tempLink,
              };
            }
            return e._deleted !== true;
          }
        })
        .sort(compare)
    : [];

  const firstPrizeKey =
    competition && competition.prizes
      ? competition.prizes[0].split("$")[0]
      : null;
  const secondPrizeKey =
    competition && competition.prizes && competition.prizes.length > 1
      ? competition && competition.prizes[1].split("$")[0]
      : null;
  const thirdPrizeKey =
    competition && competition.prizes && competition.prizes.length > 2
      ? competition.prizes[2].split("$")[0]
      : null;
  const fourthPrizeKey =
    competition && competition.prizes && competition.prizes.length > 3
      ? competition.prizes[3].split("$")[0]
      : null;
  const fifthPrizeKey =
    competition && competition.prizes && competition.prizes.length > 4
      ? competition.prizes[4].split("$")[0]
      : null;
  return (
    <React.Fragment>
      <header id="page-topbar">
        
          {Capacitor.isNativePlatform() ? (
            <NativeHeader />
          ) : (
            <>
            {isDirect ? (
              <DirectHeader theme="dark" isMenuOpened={false}></DirectHeader>
            ) : (
            <Header theme="dark" isMenuOpened={false}></Header>

            )}
            </>
          )}

        <div
          style={{ background: "#f8f9fa" }}
          className={Capacitor.isNativePlatform() && "mt-4"}
        >
          <div
            className="container pt-3"
            style={{ position: "absolute", left: 0, right: 0, marginTop: 75 }}
          >
            <div
              className="p-3 card"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isClosed && (
                <div
                  className="pill bg-pink coming-soon"
                  style={{ fontSize: 11 }}
                >
                  Exclusive
                </div>
              )}

              {competition ? (
                reactPlayer(competition.audio, competition.image)
              ) : (
                <Skeleton
                  width="70px"
                  height="70px"
                  widthRandomness={0}
                  color="#ced5e0"
                />
              )}
              <h4 className="madeGentle mt-2 mb-0 text-center">
                {competition && competition.name}
              </h4>
              <h6 className="text-muted mb-0 pb-0 Archivo">
                {competition && competition.organizer}
              </h6>
              <div
                className={`btn btn-pink gradient-pink mt-3 btn-block mb-4 Archivo ${!isActive && 'disabled'}`}
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  padding: "10px 13px",
                  fontSize: 16,
                  maxWidth: 350,
                }}
                onClick={() => {
                  setModal(!modal);
                }}
              >
                Submit Your Post
              </div>
            </div>
            {Breadcrumbs(
              competition && competition.name,
              "Contests",
              `/contests${TokenAppenditure}`
            )}

            {hasPromotion && (
              <div className="card p-3" style={{ background: "#f853bd" }}>
                <div class="section-title text-md-left">
                  <h3 class="text-white title-dark font-weight-bold madeGentle">
                    Earn ${hasPromotion.prizeAmount} By Entering Soon
                  </h3>
                  <p class="text-white mb-0">
                    The first {hasPromotion.numOfWinners} will win $
                    {hasPromotion.prizeAmount}. Please allow until 2 days after
                    the end of the competition for your payments to process.{" "}
                    {isClosed &&
                      "To participate in this contest, a NEW post must be created using the same song audio."}
                  </p>
                  <br />
                  <h5 className="text-white madeGentle">
                    {Math.round(
                      (Object.keys(creatorObj).length /
                        parseFloat(hasPromotion.numOfWinners)) *
                        100
                    )}
                    % Complete
                  </h5>
                  <Progress
                    value={
                      (Object.keys(creatorObj).length /
                        parseFloat(hasPromotion.numOfWinners)) *
                      100
                    }
                    color="#162638"
                  />
                </div>
              </div>
            )}
            {competitionType && competitionType === "555" && (
              <div className="card p-3" style={{ background: "#f853bd" }}>
                <div class="section-title text-md-left">
                  <h3 class="text-white title-dark font-weight-bold madeGentle">
                    Join the Milestone Contest: Ends at 500 Posts or on the End
                    Date!
                  </h3>
                  <p class="text-white mb-0">
                    The contest will end when 500 posts have been approved or
                    when the end date is reached, whichever happens first.{" "}
                  </p>
                  <br />
                  <h5 className="text-white madeGentle">
                    {Math.round((Object.keys(creatorObj).length / 500) * 100)}%
                    Complete
                  </h5>
                  <Progress
                    value={(Object.keys(creatorObj).length / 500) * 100}
                    color="#162638"
                  />
                </div>
              </div>
            )}
            <div className="card p-3">
              <h5
                className="madeGentle"
                style={{ display: "flex", alignSelf: "flex-start" }}
              >
                Overview:
              </h5>
              {overviewList()}
            </div>

            <div className="card p-3">
              <div className="section-title">
                <h5 className="madeGentle border-bottom pb-2">How To Enter</h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p
                    className="text-muted Archivo"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      overflow: "auto",
                    }}
                  >
                    <span>1.</span>
                    {competition && competition.creativeDirection ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: competition.creativeDirection,
                          }}
                          style={{ overflow: "auto" }}
                        ></div>
                      </>
                    ) : (
                      <span className="ml-2 Archivo">
                        Post a public{" "}
                        {!platforms
                          ? "TikTok"
                          : platforms.map((e, i) => {
                              if (i >= 1) {
                                return `or ${e} `;
                              } else {
                                return `${e} `;
                              }
                            })}{" "}
                        that features the song:{" "}
                        <a
                          target="_blank"
                          href={competition && competition.audio}
                          className="text-pink"
                        >
                          <i>{competition && competition.name}</i> by{" "}
                          <i>{competition && competition.organizer}</i>
                        </a>
                        .
                      </span>
                    )}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p
                    className="text-muted Archivo"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <span>2.</span>
                    <span className="ml-2">
                      The song must be clearly heard in the video and the post
                      must follow our{" "}
                      <a
                        style={{ cursor: "pointer", color: "#f853bd" }}
                        onClick={toggleGuidelines}
                      >
                        guidelines.
                      </a>
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p className="text-muted Archivo">
                    <span>3.</span>
                    <span className="ml-2">Submit a link to the post </span>
                    <span className={`text-pink cursor-pointer `} onClick={()=>{setModal(!modal)}}>here</span>
                  </p>
                </div>
              </div>
            </div>

            <SubmitPost
              competitionId={competitionId}
              modal={modal}
              toggleModal={toggleModal}
              referral={referral}
              myLocation={
                myLocation && myLocation.country ? myLocation.country : ""
              }
              postsArr={postsArr}
              pathname={pathName}
              competitionName={name}
              organizer={organizer}
              platforms={platforms}
              promotion={null}
              isDirect={isDirect}
              layloAPIKey={layloAPIKey}
            />
            {competitionType !== "Creative" ? (
              <Standings
                posts={postsArr}
                lastUpdated={lastUpdated}
                creatorObj={creatorObj}
              />
            ) : (
              <CreativeStandings posts={postsArr} lastUpdated={lastUpdated} />
            )}
            {competition && competition.prizes && (
              <div className="card mt-3 p-3">
                <div className="section-title">
                  <h5 className="madeGentle border-bottom pb-2">Prizes</h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p
                    className="text-muted "
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <span>
                      <i>{firstPrizeKey}</i> {returnPrizeDetails(firstPrizeKey)}
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p className="text-muted ">
                    <span>
                      <i>{secondPrizeKey}</i>{" "}
                      {returnPrizeDetails(secondPrizeKey)}
                    </span>
                  </p>
                </div>
                {competition.prizes && competition.prizes.length > 2 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p className="text-muted ">
                      <span>
                        <i>{thirdPrizeKey}</i>{" "}
                        {returnPrizeDetails(thirdPrizeKey)}
                      </span>
                    </p>
                  </div>
                )}
                {competition.prizes && competition.prizes.length > 3 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p className="text-muted ">
                      <span>
                        <i>{fourthPrizeKey}</i>{" "}
                        {returnPrizeDetails(fourthPrizeKey)}
                      </span>
                    </p>
                  </div>
                )}
                {competition.prizes && competition.prizes.length > 4 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p className="text-muted ">
                      <span>
                        <i>{fifthPrizeKey}</i>{" "}
                        {returnPrizeDetails(fifthPrizeKey)}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
      <Modal
        isOpen={guidelinesModal}
        role="dialog"
        autoFocus={true}
        centered={true}
      >
        <ModalHeader className="bg-light p-4" toggle={toggleGuidelines}>
          <span className="title">Post Guidelines</span>
        </ModalHeader>

        <ModalBody className="p-4">
          <div>
            <div>1. The song must be heard clearly in the video</div>
            <div>
              2. The post must be original content, meaning it cannot contain
              aggregated or unlicensed material.
            </div>
            <div>3. The post must be a video and cannot be a still image</div>
            <div>4. The post must not contain inappropriate content</div>
            <div>
              5. The post must not be considered spam and/or violate the
              integrity of our competitions
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleGuidelines}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ContestHeader;
