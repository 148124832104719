import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Capacitor } from "@capacitor/core";
import { Alert, Container,  } from "reactstrap";
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import Cookies from "js-cookie";

const Boost = ({uid, postID}) => {
  const [boost, setBoost] = useState(0);
  const [error, setError] = useState(null);
  const [instructions, setInstructions] = useState();
  const location = useLocation();
  const history = useHistory();
  let params = queryString.parse(location.search);
  const loginToken = Capacitor.isNativePlatform() ? params.token : Cookies.get("LoginToken"); // => 'value'
  
  useEffect(() => {
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/boost-code-detail`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({PostID: parseInt(postID), UID: uid})
       }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        // console.log("boost data",data);
        setInstructions(data.results.BoostDetail.InstructionText);
      }).catch(error => {
        setError("An Error Has Occured");
      });
  }, [])

  const handleBoostChange = (e) => {
    setBoost(e.target.value);
  };

  const [success, setSuccess] = useState();


  const reportStats = () => {
    fetch(
      `https://portalapi.genni.com/api/v1/creatorapi/boost-code-save`,
      { method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": loginToken },
        body: JSON.stringify({PostID: parseInt(postID), UID:uid, BoostCode:boost})
       }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (data.results.success) {
          // history.push(`/detail?PostID=${params.PostID}&UID=${params.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`);
          setSuccess("Boost code successfully submitted!")
        } else {
          setError("An Error Has Occured");
        }
      }).catch(error => {
        setError("An Error Has Occured");
      });
  };

  return (
    <React.Fragment>
            
      <div className="card p-3">
        {success ? (
          <Alert color='success'>{success}</Alert>
        ) : (
          <AvForm>
          <div className="campaigns">
            <div className="campaign pt-0">
              <div className="campaign-title mb-2 mt-0">
                <h5 className="madeGentle mb-0 mt-0">Enter Boost Code</h5>
                <span className="text-muted">For instructions on how to find your boost code, please see <a href="https://genni-assets.s3.amazonaws.com/Sparks-help+(1).pdf" target="_blank" className="text-pink">here.</a></span>
                {/* <span className="text-muted text-sm mb-2">{instructions ? instructions : ""}</span> */}
              </div>
              <AvField
                name="boost"
                className="inputField"
                errorMessage="Invalid Boost Code"
                value={boost}
                onChange={handleBoostChange}
                placeholder="Boost Code"
                
              />
            </div>
            
            <div className="campaign">
              <p className="text-danger">{error}</p>
              <div
                className={`btn btn-pink gradient-pink full-width float-right ${!boost && "disabled"}`}
                style={{ width: "100%" }}
                onClick={()=>{
                  if (boost) {
                    reportStats();
                  }
                }}
              >
                Submit
              </div>
            </div>
          </div>
        </AvForm>
        )}
        
      </div>
    </React.Fragment>
  );
};;

Boost.propTypes = {
  inquiries: PropTypes.array,
};

export default connect()(withRouter(Boost));
