import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Document, Page } from 'react-pdf';
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import CircularProgress from '@material-ui/core/CircularProgress';
import Loader from "react-loader-spinner";
import { Capacitor } from "@capacitor/core";
import {
  AvForm, AvField, AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import S3FileUpload from "react-s3";
import { LoginToken } from "../Utility/common";
import AcceptDecline from "./AcceptDecline";
import { MdClose } from "react-icons/md";

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

const ActionButtons = (props) => {

  const { data, platformDomain, urlForm, results, params, setActiveStep, setData, setSteps, payPalEmail } = props;

  const location = useLocation();
  let theParams = params ? params : queryString.parse(location.search);
  const loginToken = LoginToken(); // => 'value'
  const history = useHistory();
  const [campaignResponses, setCampaignResponses] = useState();

  const getCampaignResponses = () => {
    if (!campaignResponses) {
      fetch(
        `https://portalapi.genni.com/api/v1/creatorapi/campaignResponse/campaignResponseDetail`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", "x-api-key": loginToken },
          body: JSON.stringify({ postId: parseInt(theParams.PostID), uid: theParams.UID })
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          // console.log("cr",data);
          setCampaignResponses(data);
        });
    }
  }
  const s3Config = {
    bucketName: "genni-creator-assets",
    region: "us-east-1",
    accessKeyId: process.env.REACT_APP_ACCESS_KEY2,
    secretAccessKey: `${process.env.REACT_APP_SECRET_ACCESS_KEY2}`,
  };
  const [photo, setPhoto] = useState(0);
  const [file, updateFile] = useState(null);
  const [fileName, updateFileName] = useState("Upload Image");
  const [fileResult, updateFileResult] = useState(null);
  const [progress, setProgress] = useState(0);
  const [caption, setCaption] = useState("");
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [draftURL, setDraftURL] = useState();
  const [draftURLErrorMessage, setDraftURLErrorMessage] = useState();
  const [error, setError] = useState(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const toggle = () => {
    setFeedbackModal(!feedbackModal);
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function handleImageChange(event) {
    const {
      target: { value, files },
    } = event;
    const fileForUpload = files[0];
    let reader = new FileReader();
    if (
      fileForUpload.type.includes("pdf") ||
      fileForUpload.type.includes("jpeg") ||
      fileForUpload.type.includes("png") ||
      fileForUpload.type.includes("MOV") ||
      fileForUpload.type.includes("MP4") ||
      fileForUpload.type.includes("video")
    ) {
      let url = reader.readAsDataURL(fileForUpload);
      reader.onloadend = function (e) {
        updateFileResult([reader.result]);
      }.bind(this);

      if (fileForUpload) {
        setError("");
        updateFileName(fileForUpload.name.split(".")[0]);

        updateFile(fileForUpload || value);
      } else {
        setError("Must be a valid png or jpg");
      }
    } else {
      setError("Invalid File");
    }
  }

  const finishUpload = () => {
    setIsUploading(true);
    returnProgress();
    try {
      const new_file = new File([file], makeid(32), { type: file.type });
      S3FileUpload.uploadFile(new_file, s3Config)
        .then(async (d) => {
          fetch(`https://portalapi.genni.com/api/v1/creatorapi/post-approval-submit?x-api-key=${loginToken}`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "x-api-key": loginToken },
            body: JSON.stringify({ draftUrl: d.location, PostID: parseInt(theParams.PostID), UID: theParams.UID, caption: caption }), // <-- Post parameters
          })
            .then(async (res) => {
              if (res.ok) {
                return res.json();
              } else {
                await res.text().then((text) => {
                  const errText = new Error(text);
                  setError("There was an error uploading the draft.");
                });
              }
              throw res;
              //const jsonRes = JSON.parse(res);
            })
            .then((d) => {
              setData();
              setActiveStep();
              setSteps();
            });
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (err) {
      // console.log("error: ", err);
    }
  };


  useEffect(() => {
    if (data && data.results.Row_Buttons.ButtonType === "AcceptDecline") {
      getCampaignResponses();
    }
  }, [data])


  const finishUploadURL = () => {
    setIsUploading(true);
    returnProgress();
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/post-approval-submit?x-api-key=${loginToken}`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
      body: JSON.stringify({ draftUrl: draftURL, PostID: parseInt(theParams.PostID), UID: theParams.UID, caption: caption }), // <-- Post parameters
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          await res.text().then((text) => {
            const errText = new Error(text);
            setError("There was an error uploading the draft.");
          });
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((d) => {
        setData();
        setActiveStep();
        setSteps();
      });
  };
  const handleDraftURLChange = (e) => {
    setDraftURL(e.target.value);
  };

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const deleteResult = (postResultId, uuid) => {
    fetch(
      `https://api.genni.com/campaignResultsDelete?x-api-key=${loginToken}&postResultId=${postResultId}&uid=${uuid}`,
      { method: "POST" }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          setData();
          setActiveStep();
          setSteps();
        }
        // window.location.href = `/?LoginToken=${params.LoginToken}`;
      });
  };

  const draftUploadReady = data && (file || (draftURL && isValidHttpUrl(draftURL))) && ((data && data.results.DraftCaptionRequired && caption) || !data.results.DraftCaptionRequired);
  const returnProgress = () => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 5 : prevProgress + 2));
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }
  const submitFinalResults = () => {
    fetch(
      `https://api.genni.com/campaignResultsSummary?x-api-key=${loginToken}&postId=${theParams.PostID}&uid=${theParams.UID}`,
      { method: "POST" }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        setData();
        setActiveStep();
        setSteps();
      });
  }
  // console.log("campaign data",data);
  if (data && data.results.Row_Buttons.ButtonType === "AcceptDecline" && campaignResponses !== undefined) {
    return (<AcceptDecline payPalEmail={payPalEmail} data={data} campaignResponses={campaignResponses} loginToken={loginToken} params={theParams} setData={setData} setSteps={setSteps} setActiveStep={setActiveStep} />);
  } else if (
    data &&
    data.results.Row_Buttons.ButtonType === "Draft" &&
    !data.results.DraftSubmitted
  ) {
    return (
      <>
        {data && data.results.FeedbackNotes && (
          <>
            <div className="border" style={{
              borderRadius: '8px',
              padding: '20px',
              marginTop: '1rem',
              marginBottom: '1rem',
              backgroundColor: '#fff',
            }}>
              <div className="rounded-full mb-2" style={{ background: 'rgb(239, 68, 68)', height: 42, width: 42, borderRadius: '100%', display: 'flex', justifyContent: 'center', margin: "AUTO", alignItems: 'center' }}>
                <MdClose size={36} color="#fff" />
              </div>
              <h3 className="madeGentle" style={{ textAlign: 'center' }}>Draft Rejected</h3>
              <h4 style={{ marginTop: '20px', fontSize: 14 }}>Feedback:</h4>
              <div style={{
                marginTop: '0px',
                color: '#555',
                textAlign: 'left',
                backgroundColor: '#f9f9f9',
                padding: '10px',
                borderRadius: '4px',
                border: '1px solid #ddd'
              }}
                dangerouslySetInnerHTML={{ __html: data.results.FeedbackNotes }} />
            </div>
          </>
        )}


        <div className="card p-3">
          {error && <Alert color="danger">{error}</Alert>}

          <h6 className="madeGentle mb-0 pb-0">Upload Draft Of Post</h6>
          <p className="text-muted pt-0 mt-0">
            Please upload a draft version of your post for approval before
            posting.
          </p>
          {file ? (
            <>
              <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                {file.name}
                <div className="">

                  <div
                    className="btn btn-secondary full-width float-right mx-1"
                    onClick={() => {
                      updateFile(null);
                      updateFileResult(null);
                    }}
                  >
                    Delete
                  </div>
                </div>
              </div>
              {isUploading && (
                <span className="text-muted">
                  Please allow several minutes to upload.
                </span>
              )}
            </>
          ) : (
            <>
              {error && <p className="text-danger">{error}</p>}
              <label
                htmlFor="file-upload"
                className="btn btn-pink float-right mb-3 mt-0"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: '150px',
                  fontSize: "16px",
                }}
              >
                <i className="mdi mdi-cloud-upload mr-2"></i>Upload File
              </label>
              <input
                type="file"
                id="file-upload"
                onChange={handleImageChange}
                style={{ width: "140px", display: "none" }}
              />
            </>
          )}
          {data && data.results.DraftCaptionRequired && (
            <>
              <AvForm>
                <AvField
                  name="caption"
                  label="Enter Proposed Caption: (Required)"
                  className="inputField"
                  placeholder={`Ex. Can you believe this happened? #lol`}
                  type="textarea"
                  onChange={handleCaptionChange}
                  value={caption}
                />
              </AvForm>
            </>
          )}
          {data && data.results.Platform.includes("YouTube") && (
            <>
              <span>Or</span>
              <AvForm>
                <AvField
                  name="url"
                  label="Enter Draft URL"
                  className="inputField"
                  placeholder={`Ex. https://${platformDomain
                    }.com`}
                  type="text"
                  errorMessage="Invalid Post URL"
                  onChange={handleDraftURLChange}
                  value={draftURL}
                />

              </AvForm>
            </>
          )}
          <div
            className={`btn btn-pink gradient-pink full-width mt-3 ${draftUploadReady ? "" : "disabled"}`}
            style={{ maxHeight: 48 }}
            onClick={() => {
              if (file) {
                finishUpload();
              } else {
                finishUploadURL();
              }
            }}
          >
            {isUploading ? (
              <CircularProgress
                variant="determinate" value={progress}
              />
            ) : (
              "Submit"
            )}
          </div>
          {/* {draftUploadReady ? "" : (<span className="text-danger mt-2">Please fill in all fields</span>)} */}
        </div>

      </>
    );
  } else if (
    data &&
    data.results.Step.Current === "Under Review"
  ) {
    return (
      <>
        <Col xs={12} sm={6} md={6} xl={6}>
          <Card
            style={{ borderRadius: 12, cursor: "pointer" }}
            onClick={() => {
              fetch(
                `https://portalapi.genni.com/api/v1/creatorapi/post-approval-delete`,
                {
                  method: "DELETE",
                  headers: { "Content-Type": "application/json", "x-api-key": loginToken },
                  body: JSON.stringify({ PostID: parseInt(theParams.PostID), UID: theParams.UID })
                }
              )
                .then((res) => {
                  if (res.ok) {
                    return res.json();
                  }
                  throw res;
                  //const jsonRes = JSON.parse(res);
                })
                .then((data) => {
                  setData();
                  setActiveStep();
                  setSteps();
                  setIsUploading(false);
                  updateFile(null);
                  updateFileName(null);

                  // window.location.href = `/?LoginToken=${params.LoginToken}`;
                });
            }}
          >
            <CardBody style={{ borderRadius: 12 }} className="p-2">
              <div
                className="d-flex"
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  key={"active_9"}
                  className="mdi mdi-close-circle text-pink"
                  style={{ fontSize: 28 }}
                />
                <h5 className="madeGentle">
                  Delete Draft
                </h5>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  } else if (
    data &&
    data.results.Row_Buttons.ButtonType === "Notify"
  ) {
    return (
      <>
        {urlForm}
      </>
    );
  } else if (data && data.results.Row_Buttons.ButtonType === "Report") {
    if (!data.results.PostDeadline.DeadlineAction.includes("Wait")) {

      const rows = results && results.data.Row_PostResultsTable.Table.Row;
      const headerTitles =
        results && results.data.Row_PostResultsTable.Table.HeaderTitles;
      const calcTotal = (n) => {
        let total = 0;
        rows && rows.map(e => {
          total += e.RowData[n];
        });
        return total;
      };
      if (rows && rows.length > 0) {
        return (
          <>
            <div className="card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h5 className="madeGentle mx-2">Results</h5>
                <div
                  className="btn btn-secondary mb-2 float-right mt-1 mx-2 gradient-navy"
                  style={{ maxWidth: 150, float: "right" }}
                  onClick={() => {
                    history.push(`/report/?PostID=${theParams.PostID}&UID=${theParams.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`);
                  }}
                >
                  <i className="mdi mdi-plus text-white " />
                  Add New Result
                </div>
              </div>
              <Table>
                <thead>
                  <tr>
                    <td style={{ minWidth: 40, maxWidth: 50 }}>Delete</td>
                    {headerTitles.map((e, i) => {
                      return <td>{e}</td>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.map((e) => {
                      return (
                        <tr>
                          <td>
                            <div
                              className="btn-secondary gradient-navy"
                              onClick={() => {
                                deleteResult(e.PostResultID, e.UID);
                              }}
                              style={{ borderRadius: 4, textAlign: "center" }}
                            >
                              {" "}
                              <i className="mdi mdi-delete text-white" />
                            </div>
                          </td>
                          {e.RowData.map((i) => {
                            return <td>{i}</td>;
                          })}
                        </tr>
                      );
                    })}
                  <tr style={{ borderTop: "2px solid #162638" }}>
                    <td>
                      <b>Totals</b>
                    </td>
                    <td>{calcTotal(0)}</td>
                    <td>{calcTotal(1)}</td>
                    <td>{calcTotal(2)}</td>
                    <td>{calcTotal(3)}</td>
                    <td>{calcTotal(4)}</td>
                  </tr>
                </tbody>
              </Table>

              <div
                className="btn btn-pink full-width"
                onClick={() => {
                  submitFinalResults();
                }}
              >
                Submit Final Results
              </div>
            </div>
          </>
        );
      } else {
        return (
          <Col xs={12} sm={6} md={6} xl={6}>
            {data.results.PostDeadline.DeadlineText && (
              <Card
                style={{ borderRadius: 12, cursor: "pointer" }}
                onClick={() => {
                  history.push(`/report/?PostID=${theParams.PostID}&UID=${theParams.UID}${Capacitor.isNativePlatform() ? `&token=${loginToken}` : ""}`);
                }}
              >
                <CardBody style={{ borderRadius: 12 }} className="p-2">
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      key={"active_9"}
                      className="mdi mdi-note text-pink"
                      style={{ fontSize: 28 }}
                    />
                    <h5 className="madeGentle">
                      {data && data.results.PostDeadline.DeadlineText}
                    </h5>
                  </div>
                </CardBody>
              </Card>
            )}


          </Col>
        );
      }
    }
  } else {
    return "";
  }
};

export default ActionButtons;