
import { useState } from "react";
import { CommonLoader, LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { get } from "lodash";
import "moment-timezone";
import Boost from "./boost";
import { TbAlertCircleFilled } from "react-icons/tb";

const calcDateValue = (d) => {
  const t = new Date("January 1 1970 00:00:00-0:00");
  t.setSeconds(t.getSeconds() + parseFloat(d));
  const momentT = moment(t).tz("America/Chicago"); // Ensures CDT
  return momentT.format("M/D/YYYY") === "Invalid date"
    ? ""
    : momentT.format("MMM D hh:mmA z");
};

const Alerts = (props) => {
  const { data, isNotifyMode, params, postID, uid } = props;
  const history = useHistory();
  const [showBoostForm, setShowBoostForm] = useState();
  if (data) {
    if (data.results.Row_Buttons.ButtonType === "AcceptDecline") {
      return (
        <>
          <Alert color="pink">
            <i className="mdi mdi-alarm"></i>
            Accept or Decline {data.results.Row_Buttons.ButtonSubText}{" "}
            {`${data.results.Row_Buttons.ButtonDateTime}`}
          </Alert>

        </>
      );
    } else if (
      data &&
      data.results.Row_Buttons.ButtonType === "Draft" &&
      !data.results.DraftSubmitted
    ) {
      return (
        <>
          <Alert color="pink">
            <i className="mdi mdi-alarm"></i>
            Upload Draft Of Post {data.results.Row_Buttons.ButtonSubText}{" "}
            {data.results.Row_Buttons.ButtonDateTime}
          </Alert>

        </>
      );
    } else if (
      data &&
      data.results.Row_Buttons.ButtonType === "Draft" &&
      data.results.DraftSubmitted
    ) {
      return (
        <>
          <Alert color="success">
            <i className="mdi mdi-check-circle"></i>
            Draft Upload Success! The client is reviewing your post for
            approval. Please check back soon.
          </Alert>
        </>
      );
    } else if (
      data &&
      data.results.Row_Buttons.ButtonType === "Notify" &&
      !isNotifyMode
    ) {
      return (
        <>
          <Alert color="pink">
            <i className="mdi mdi-alarm"></i>
            Upload Post URL{" "}
            {data.results.Row_Buttons.ButtonSubText}{" "}
            {data.results.Row_Buttons.ButtonDateTime}
          </Alert>

        </>
      );
    } else if (data.results.Row_Buttons.ButtonType === "Report") {
      if (data.results.PostDeadline.DeadlineAction.includes("Wait")) {
        return (
          <Alert color="danger">

            {data.results.PostDeadline.DeadlineText}{" "}
            {data.results.Row_Buttons.ButtonDateTime}
          </Alert>
        );
      } else {
        return (
          <Alert color="danger">

            {data.results.PostDeadline.DeadlineText}{" "}
            {data.results.Row_Buttons.ButtonSubText}{" "}
            {data.results.Row_Buttons.ButtonDateTime}
          </Alert>
        );
      }
    } else if (data.results.PostDeadline.DeadlineText === "Awaiting Contract Negotiation") {
      return (<Alert color="success">
        <i className="mdi mdi-check-circle"></i>
        Contract Upload Success! The client is reviewing your contract revisions for approval. Please check back soon.
      </Alert>);
    } else if (data.results.Row_Buttons.ButtonType === "") {
      const postStatus = get(data, ["results", "Row_CampaignDetails", 3, "Row_CampaignDetails_Fields", 5, "FieldValue"], "");
      const returnFinishedCampaignMessage = () => {
        if (postStatus === "Failed") {
          return (
            <Alert color="danger">
              <i className="mdi mdi-information"></i>
              The campaign failed due to a missed deadline.{" "}
            </Alert>
          )
        } else if (postStatus === "Expired") {
          return (
            <Alert color="danger">
              <i className="mdi mdi-information"></i>
              The campaign expired due to a missed deadline.{" "}
            </Alert>
          )
        } else if (postStatus === "Pulled") {
          return (
            <Alert color="warning">
              <i className="mdi mdi-information"></i>
              The offer was pulled by the client. We apologize for the inconvenience.{" "}
            </Alert>
          )
        }
        else {
          return (
            <Alert color="success">
              <i className="mdi mdi-check-circle"></i>
              This campaign has been successfully completed!{" "}
            </Alert>
          )
        }
      }
      return (
        <>
          {returnFinishedCampaignMessage()}

          {data &&
            data.results.RequestBoostCode &&
            !data.results.SubmittedBoostCode && postStatus !== "Failed" && (
              <>
                {showBoostForm ? (
                  <Boost postID={postID} uid={uid} />
                ) : (
                  <Card
                    style={{ borderRadius: 12, cursor: "pointer", width: "100%" }}

                  >
                    <CardBody style={{ borderRadius: 12 }} className="p-2 " onClick={() => { setShowBoostForm(true) }}>
                      <div
                        className="d-flex"
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i
                          key={"active_9"}
                          className="mdi mdi-rocket text-pink"
                          style={{ fontSize: 28 }}
                        />
                        <h5 className="madeGentle">Enter Boost Code</h5>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </>

            )}
        </>
      );
    }
  }

  return "";
};

export default Alerts;