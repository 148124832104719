const countriesContinentList =  [{CC: "CX", continent: "Asia"},
{CC: "ER", continent: "Africa"},
{CC: "WS", continent: "Oceania"},
{CC: "MK", continent: "Europe"},
{CC: "DJ", continent: "Africa"},
{CC: "JO", continent: "Asia"},
{CC: "PK", continent: "Asia"},
{CC: "PF", continent: "Oceania"},
{CC: "IE", continent: "Europe"},
{CC: "MR", continent: "Africa"},
{CC: "DK", continent: "Europe"},
{CC: "NA", continent: "Africa"},
{CC: "GH", continent: "Africa"},
{CC: "SS", continent: "Africa"},
{CC: "SK", continent: "Europe"},
{CC: "AS", continent: "Oceania"},
{CC: "MD", continent: "Europe"},
{CC: "KP", continent: "Asia"},
{CC: "MO", continent: "Asia"},
{CC: "TC", continent: "North America"},
{CC: "SC", continent: "Africa"},
{CC: "AM", continent: "Asia"},
{CC: "CW", continent: "North America"},
{CC: "GP", continent: "North America"},
{CC: "IO", continent: "Asia"},
{CC: "GQ", continent: "Africa"},
{CC: "SY", continent: "Asia"},
{CC: "AO", continent: "Africa"},
{CC: "TJ", continent: "Asia"},
{CC: "VE", continent: "South America"},
{CC: "UM", continent: "Oceania"},
{CC: "AX", continent: "Europe"},
{CC: "PL", continent: "Europe"},
{CC: "SA", continent: "Asia"},
{CC: "CG", continent: "Africa"},
{CC: "BA", continent: "Europe"},
{CC: "VI", continent: "North America"},
{CC: "NC", continent: "Oceania"},
{CC: "SM", continent: "Europe"},
{CC: "CZ", continent: "Europe"},
{CC: "GT", continent: "North America"},
{CC: "KR", continent: "Asia"},
{CC: "EE", continent: "Europe"},
{CC: "NP", continent: "Asia"},
{CC: "XK", continent: "Europe"},
{CC: "BW", continent: "Africa"},
{CC: "PH", continent: "Asia"},
{CC: "PM", continent: "North America"},
{CC: "IQ", continent: "Asia"},
{CC: "LB", continent: "Asia"},
{CC: "BI", continent: "Africa"},
{CC: "MN", continent: "Asia"},
{CC: "FO", continent: "Europe"},
{CC: "PY", continent: "South America"},
{CC: "SJ", continent: "Europe"},
{CC: "TK", continent: "Oceania"},
{CC: "GG", continent: "Europe"},
{CC: "BQ", continent: "North America"},
{CC: "DZ", continent: "Africa"},
{CC: "FR", continent: "Europe"},
{CC: "NL", continent: "Europe"},
{CC: "GL", continent: "North America"},
{CC: "SX", continent: "North America"},
{CC: "TD", continent: "Africa"},
{CC: "FI", continent: "Europe"},
{CC: "PA", continent: "North America"},
{CC: "PS", continent: "Asia"},
{CC: "YE", continent: "Asia"},
{CC: "BR", continent: "South America"},
{CC: "NG", continent: "Africa"},
{CC: "PW", continent: "Oceania"},
{CC: "JP", continent: "Asia"},
{CC: "TZ", continent: "Africa"},
{CC: "KZ", continent: "Asia"},
{CC: "PT", continent: "Europe"},
{CC: "GS", continent: "Antarctica"},
{CC: "LK", continent: "Asia"},
{CC: "DE", continent: "Europe"},
{CC: "EH", continent: "Africa"},
{CC: "MM", continent: "Asia"},
{CC: "CO", continent: "South America"},
{CC: "PE", continent: "South America"},
{CC: "SN", continent: "Africa"},
{CC: "KG", continent: "Asia"},
{CC: "CN", continent: "Asia"},
{CC: "AF", continent: "Asia"},
{CC: "TR", continent: "Europe"},
{CC: "LR", continent: "Africa"},
{CC: "ZA", continent: "Africa"},
{CC: "CL", continent: "South America"},
{CC: "MT", continent: "Europe"},
{CC: "KI", continent: "Oceania"},
{CC: "CV", continent: "Africa"},
{CC: "JE", continent: "Europe"},
{CC: "IS", continent: "Europe"},
{CC: "GM", continent: "Africa"},
{CC: "VU", continent: "Oceania"},
{CC: "UY", continent: "South America"},
{CC: "OM", continent: "Asia"},
{CC: "SD", continent: "Africa"},
{CC: "IN", continent: "Asia"},
{CC: "LA", continent: "Asia"},
{CC: "AW", continent: "North America"},
{CC: "MQ", continent: "North America"},
{CC: "KM", continent: "Africa"},
{CC: "ES", continent: "Europe"},
{CC: "AG", continent: "North America"},
{CC: "UZ", continent: "Asia"},
{CC: "MV", continent: "Asia"},
{CC: "GI", continent: "Europe"},
{CC: "ID", continent: "Asia"},
{CC: "PN", continent: "Oceania"},
{CC: "VN", continent: "Asia"},
{CC: "MY", continent: "Asia"},
{CC: "CK", continent: "Oceania"},
{CC: "SZ", continent: "Africa"},
{CC: "UG", continent: "Africa"},
{CC: "MG", continent: "Africa"},
{CC: "FJ", continent: "Oceania"},
{CC: "NO", continent: "Europe"},
{CC: "AQ", continent: "Antarctica"},
{CC: "BV", continent: "Antarctica"},
{CC: "CY", continent: "Europe"},
{CC: "TW", continent: "Asia"},
{CC: "PG", continent: "Oceania"},
{CC: "RW", continent: "Africa"},
{CC: "CD", continent: "Africa"},
{CC: "CM", continent: "Africa"},
{CC: "RS", continent: "Europe"},
{CC: "LT", continent: "Europe"},
{CC: "TF", continent: "Antarctica"},
{CC: "KH", continent: "Asia"},
{CC: "SH", continent: "Africa"},
{CC: "AE", continent: "Asia"},
{CC: "BM", continent: "North America"},
{CC: "MC", continent: "Europe"},
{CC: "CU", continent: "North America"},
{CC: "HK", continent: "Asia"},
{CC: "MP", continent: "Oceania"},
{CC: "TG", continent: "Africa"},
{CC: "LU", continent: "Europe"},
{CC: "MU", continent: "Africa"},
{CC: "AR", continent: "South America"},
{CC: "GD", continent: "North America"},
{CC: "NI", continent: "North America"},
{CC: "NU", continent: "Oceania"},
{CC: "UA", continent: "Europe"},
{CC: "GY", continent: "South America"},
{CC: "NE", continent: "Africa"},
{CC: "BJ", continent: "Africa"},
{CC: "LC", continent: "North America"},
{CC: "TV", continent: "Oceania"},
{CC: "NF", continent: "Oceania"},
{CC: "EG", continent: "Africa"},
{CC: "KN", continent: "North America"},
{CC: "LS", continent: "Africa"},
{CC: "TO", continent: "Oceania"},
{CC: "GE", continent: "Asia"},
{CC: "ET", continent: "Africa"},
{CC: "DO", continent: "North America"},
{CC: "VC", continent: "North America"},
{CC: "BZ", continent: "North America"},
{CC: "IM", continent: "Europe"},
{CC: "MA", continent: "Africa"},
{CC: "HT", continent: "North America"},
{CC: "YT", continent: "Africa"},
{CC: "BF", continent: "Africa"},
{CC: "BD", continent: "Asia"},
{CC: "KW", continent: "Asia"},
{CC: "RE", continent: "Africa"},
{CC: "JM", continent: "North America"},
{CC: "RO", continent: "Europe"},
{CC: "ST", continent: "Africa"},
{CC: "BS", continent: "North America"},
{CC: "MX", continent: "North America"},
{CC: "MF", continent: "North America"},
{CC: "SV", continent: "North America"},
{CC: "BL", continent: "North America"},
{CC: "FM", continent: "Oceania"},
{CC: "TM", continent: "Asia"},
{CC: "AI", continent: "North America"},
{CC: "CF", continent: "Africa"},
{CC: "SR", continent: "South America"},
{CC: "BE", continent: "Europe"},
{CC: "SE", continent: "Europe"},
{CC: "BO", continent: "South America"},
{CC: "ME", continent: "Europe"},
{CC: "MZ", continent: "Africa"},
{CC: "LV", continent: "Europe"},
{CC: "MW", continent: "Africa"},
{CC: "ML", continent: "Africa"},
{CC: "VA", continent: "Europe"},
{CC: "MS", continent: "North America"},
{CC: "AT", continent: "Europe"},
{CC: "AL", continent: "Europe"},
{CC: "VG", continent: "North America"},
{CC: "ZM", continent: "Africa"},
{CC: "GF", continent: "South America"},
{CC: "LI", continent: "Europe"},
{CC: "QA", continent: "Asia"},
{CC: "SB", continent: "Oceania"},
{CC: "NR", continent: "Oceania"},
{CC: "GR", continent: "Europe"},
{CC: "LY", continent: "Africa"},
{CC: "GW", continent: "Africa"},
{CC: "BB", continent: "North America"},
{CC: "HN", continent: "North America"},
{CC: "SO", continent: "Africa"},
{CC: "KY", continent: "North America"},
{CC: "IT", continent: "Europe"},
{CC: "WF", continent: "Oceania"},
{CC: "AD", continent: "Europe"},
{CC: "EC", continent: "South America"},
{CC: "KE", continent: "Africa"},
{CC: "BH", continent: "Asia"},
{CC: "GB", continent: "Europe"},
{CC: "GN", continent: "Africa"},
{CC: "HM", continent: "Antarctica"},
{CC: "RU", continent: "Europe"},
{CC: "BN", continent: "Asia"},
{CC: "ZW", continent: "Africa"},
{CC: "GU", continent: "Oceania"},
{CC: "AU", continent: "Oceania"},
{CC: "SI", continent: "Europe"},
{CC: "BY", continent: "Europe"},
{CC: "TH", continent: "Asia"},
{CC: "NZ", continent: "Oceania"},
{CC: "TN", continent: "Africa"},
{CC: "MH", continent: "Oceania"},
{CC: "SL", continent: "Africa"},
{CC: "BT", continent: "Asia"},
{CC: "US", continent: "North America"},
{CC: "CH", continent: "Europe"},
{CC: "FK", continent: "South America"},
{CC: "CC", continent: "Asia"},
{CC: "GA", continent: "Africa"},
{CC: "DM", continent: "North America"},
{CC: "CA", continent: "North America"},
{CC: "TT", continent: "North America"},
{CC: "PR", continent: "North America"},
{CC: "SG", continent: "Asia"},
{CC: "HU", continent: "Europe"},
{CC: "CR", continent: "North America"},
{CC: "IL", continent: "Asia"},
{CC: "BG", continent: "Europe"},
{CC: "AZ", continent: "Europe"},
{CC: "TL", continent: "Oceania"},
{CC: "IR", continent: "Asia"},
{CC: "HR", continent: "Europe"},
{CC: "CI", continent: "Africa"}]

export default countriesContinentList;