import React, {useEffect, useState} from "react";

const ContestDetail = () => {
  
  return (
    <React.Fragment>
      <div
        className="page-content "
        style={{ height: "1500px", background: "#f8f9fa" }}
      ></div>
    </React.Fragment>
  );
};

export default ContestDetail;