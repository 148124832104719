import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

import Rightbar from "../CommonForBoth/Rightbar";
// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../assets/images/Logo.png";
//i18n
import { withTranslation } from "react-i18next";
import { TokenAppenditure, LoginToken } from "../../pages/Utility/common";

const Header = props => {
  const [showMenu, setShowMenu] = useState(false);
  const {data, isLoading} = props;
  
  return (
    <React.Fragment>
      
      <div className="navbar-container border-bottom">
      
        <div className="navbar-header container">
          <div className="d-flex ">
            <div className="navbar-brand-box">
              <Link to={`/dashboard${TokenAppenditure()}`} className="">
                <h3 className="logo-lg madeGentle">
                  <img src={logo} alt="genni" className="nav-logo" />
                </h3>
              </Link>
            </div>
          </div>
          <div className="d-flex">
            <Link to={`/hello`}>
            <div className="btn btn-pink gradient-pink Archivo">View Contests</div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
