import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, } from "availity-reactstrap-validation";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom"
import queryString from "query-string";
import S3FileUpload from "react-s3";
import Loader from "react-loader-spinner";
import {
  Row,
  Col,
  Alert
} from "reactstrap";
import { LoginToken, TokenAppenditure, Breadcrumbs } from "../Utility/common";
import { TbAlertCircleFilled } from "react-icons/tb";


function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


const Tax = () => {
  const s3Config = {
    bucketName: "genni-creator-assets",
    region: "us-east-1",
    accessKeyId: process.env.REACT_APP_ACCESS_KEY2,
    secretAccessKey: `${process.env.REACT_APP_SECRET_ACCESS_KEY2}`,
  };
  const [photo, setPhoto] = useState(0);
  const [errorMessage,setErrorMessage] = useState();
  const [file, updateFile] = useState(null);
  const [fileName, updateFileName] = useState("Upload Image");
  const [fileResult, updateFileResult] = useState(null);
  const location = useLocation();
  const loginToken = LoginToken(); // => 'value'
  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState();
  function handleImageChange(event) {
    const {
      target: { value, files },
    } = event;

    const fileForUpload = files[0];
    // console.log("file", fileForUpload);
    if (fileForUpload.type.includes("pdf") || fileForUpload.type.includes("jpeg") || fileForUpload.type.includes("png")){
      let reader = new FileReader();
      let url = reader.readAsDataURL(fileForUpload);

      reader.onloadend = function (e) {
        updateFileResult([reader.result]);
      }.bind(this);
      if (fileForUpload) {
        updateFileName(fileForUpload.name.split(".")[0]);
        updateFile(fileForUpload || value);
      }
    } else {
      setErrorMessage("Invalid File");
    }


  }
  const finishUpload = () => {
    setIsLoading(true);
    try {
      const new_file = new File([file], makeid(32));
      S3FileUpload.uploadFile(new_file, s3Config)
        .then(async (data) => {

          fetch(
            `https://portalapi.genni.com/api/v1/creatorapi/tax-save`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json", "x-api-key": loginToken },
              body: JSON.stringify({fileUrl: data.location})
            }
          )
            .then(async(res) => {
              if (res.ok) {
                return res.json();
              } else {
                await res.text().then((text) => {
                  const errText = new Error(text);
                  setErrorMessage(JSON.parse(errText.message).messages[0]);
                });
              }
              throw res;
              //const jsonRes = JSON.parse(res);
            })
            .then((data) => {
              if (!data.error) {
                window.location.reload();
              } else {
                // setError(data.results.FormSubmissionResponseMessage);
              }
            });
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (err) {
      // console.log("error: ", err);
    }
  };
  const deleteTaxDoc = () => {
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/tax-delete`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          window.location.reload();
        } else {
          // window.location.href = "/login";
        }
      });
  };
  useEffect(() => {
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/tax`, 
    { 
      method: "GET",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        setData(data);
      });
  }, []);
  // console.log(data);
  // console.log(fileResult);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12 mt-0">
            {Breadcrumbs("Tax Documents", "Account Settings", `/settings${TokenAppenditure()}`, true)}

              {/* <div className="splashy-banner mt-1 card">
                <div className="left-splashy">
                  <img src={GroupIMG} style={{height: 60}} />
                </div>
                <div className="px-4 py-3">
                  <h5 className="mb-1">Upload W-9</h5>
                  <p className="text-muted mb-1">By Jan 2nd</p>
                  <div className="btn btn-pink mb-1">Upload Now</div>
                </div>
              </div> */}
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 madeGentle">Required Tax Documents</h4>
              </div>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              {data && data.results.TaxForm_IsUploaded && (
                <Alert color="success">You have successfully uploaded your tax documents.</Alert>
              )}
              <div className="card p-3">
                {data && data.results.TaxForm_IsUploaded && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <a
                      className="btn btn-pink full-width Archivo"
                      href={`${data.results.TaxForm_URL}`}
                      target="_blank"
                      style={{ width: "100%" }}
                    >
                      View
                    </a>
                    <div
                      className="btn btn-outline-pink full-width Archivo"
                      style={{ width: "100%" }}
                      onClick={() => {
                        deleteTaxDoc();
                      }}
                    >
                      Delete
                    </div>
                  </div>
                )}
                <br />
                {data && (
                  <div
                    className="Archivo"
                    dangerouslySetInnerHTML={{
                      __html: data.results.TaxFormInformationHTMLMessage,
                    }}
                  />
                )}

                <br />
                <AvForm>
                  <div className="campaigns">
                    <div className="campaign">
                      <div className="campaign-title">
                        <h5 className="text-black madeGentle">
                          Upload Tax Document (JPG, PNG, or PDF FILE)
                        </h5>
                      </div>
                    </div>
                    <div className="campaign">
                      {file ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {fileResult &&
                          fileResult[0].includes("application/pdf") ? (
                            <embed src={fileResult} width="40px" />
                          ) : (
                            <img
                              src={fileResult}
                              alt="file"
                              style={{ width: 40 }}
                            />
                          )}

                          <div className="mt-2">
                            <div
                              className="btn btn-pink full-width float-right"
                              onClick={() => {
                                finishUpload();
                              }}
                            >
                              {isLoading ? (
                                <Loader
                                  type="Bars"
                                  color="#fff"
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                "Submit"
                              )}
                            </div>
                            <div
                              className="btn btn-secondary full-width float-right my-3"
                              onClick={() => {
                                updateFile(null);
                                updateFileResult(null);
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <label
                            htmlFor="file-upload"
                            className="btn btn-pink gradient-pink full-width float-right"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "16px",
                            }}
                          >
                            <i className="mdi mdi-cloud-upload mr-2"></i>Upload File
                          </label>
                          <input
                            type="file"
                            id="file-upload"
                            onChange={handleImageChange}
                            style={{ width: "140px", display: "none" }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};;

export default Tax;
